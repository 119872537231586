<template>
  <div class="layout-header-section d-lg-flex d-block justify-content-between">
    <div class="header-label col-lg-12 col-12">My Profile</div>
  </div>
  <div class="layout-box-content-section" style="height: calc(100vh - 141px)">
    <div class="row">
      <div class="col-6">
        <div class="myprofile-avatar-outer">
          <img
            src="/assets/images/profile-10.jpg"
            class="myprofile-avatar rounded-circle"
            alt=""
            width="77"
          />
          <div class="custom-image-upload">
            <label for="file-input">
              <img
                src="/assets/images/icon/profile-img-edit.svg"
                alt=""
                width="19"
              />
            </label>
            <input
              id="file-input"
              @change="previewFiles"
              ref="userprofileFiles"
              type="file"
            />
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-12 align-self-end text-end">
        <div class="custom-form-group">
          <button
            type="button"
            @click="profileeditbtn"
            class="btn custom-view-detail-btn"
          >
            <i class="far fa-edit me-2"></i>Edit
          </button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-3">
        <div class="custom-form-group">
          <label for="formfirstnameInput" class="form-label">First Name</label>
          <div class="input-group custom-input-group">
            <div class="col-lg-4 col-md-4 col-12">
              <Multiselect
                v-model="title"
                :options="titlelist"
                :searchable="false"
                label="label"
                placeholder="Select"
                class="multiselect-custom text-capitalize"
                :canClear="false"
                :closeOnSelect="true"
                :object="true"
                disabled
                noOptionsText="No Result"
                :canDeselect="false"
              />
            </div>
            <div class="col-lg-8 col-md-8 col-12">
              <input
                type="text"
                v-model="firstname"
                class="form-control"
                id="formfirstnameInput"
                placeholder="Enter Your First Name"
                autocomplete="off"
                disabled
              />
            </div>
          </div>
          <div class="custom-error" v-if="v$.firstname.$error">
            {{ v$.firstname.$errors[0].$message }}
          </div>
        </div>
      </div>
      <div class="col-3">
        <div class="custom-form-group">
          <label for="formlastnameInput" class="form-label">Last Name</label>
          <input
            type="text"
            v-model="lastname"
            class="form-control"
            id="formlastnameInput"
            placeholder="Enter Your Last Name"
            autocomplete="off"
            disabled
          />
          <div class="custom-error" v-if="v$.lastname.$error">
            {{ v$.lastname.$errors[0].$message }}
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-3">
        <div class="custom-form-group">
          <label for="formemailInput" class="form-label">Email Id</label>
          <input
            type="text"
            v-model="email"
            class="form-control"
            id="formemailInput"
            placeholder="Enter Your Email Id"
            autocomplete="off"
            disabled
          />
          <div class="custom-error" v-if="v$.email.$error">
            {{ v$.email.$errors[0].$message }}
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-3">
        <div class="custom-form-group">
          <label for="formcontactInput" class="form-label">Mobile Number</label>
          <div class="input-group custom-input-group">
            <div class="col-lg-4 col-md-4 col-12">
              <Multiselect
                v-model="countrycode"
                :options="countrycodelist"
                :searchable="true"
                label="label"
                placeholder="Select"
                class="multiselect-custom"
                :canClear="false"
                :closeOnSelect="true"
                :object="true"
                disabled
                noOptionsText="No Result"
                :canDeselect="false"
              />
            </div>
            <div class="col-lg-8 col-md-8 col-12">
              <input
                type="text"
                v-model="contactnumber"
                class="form-control"
                id="formcontactInput"
                placeholder="Enter Your Contact No"
                autocomplete="off"
                disabled
                maxlength="10"
                @keypress="onlyNumber"
              />
            </div>
          </div>
          <div class="custom-error" v-if="v$.contactnumber.$error">
            {{ v$.contactnumber.$errors[0].$message }}
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 col-md-12 col-12">
        <div class="custom-form-group">
          <button
            type="button"
            disabled
            @click="profileupdatebtn()"
            class="btn custom-view-detail-btn"
          >
            Save
          </button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-3">
        <button
          class="btn btn-link profile-reset-password-btn"
          @click="resetpasswordModalOpen()"
        >
          Change Password
        </button>
      </div>
    </div>
  </div>
  <div class="modal-mask" v-if="resetpasswordmodalstatus">
    <div class="modal-dialog modal-xs modal-dialog-centered custom-modal-outer">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">Change Password</h5>
          <button
            type="button"
            class="btn-close"
            @click="resetpasswordModalClose()"
          ></button>
        </div>
        <ResetPasswordModal />
      </div>
    </div>
  </div>
</template>
<script>
import { required, helpers } from "@vuelidate/validators";
import useValidate from "@vuelidate/core";

export default {
  data() {
    return {
      v$: useValidate(),
      title: { label: "Mr.", value: "1" },
      titlelist: [
        { label: "Mr.", value: "1" },
        { label: "Mrs.", value: "2" },
      ],
      firstname: "Kamalakar",
      lastname: "Patil",
      email: "tester@gmail.com",
      countrycode: { label: "+91", value: "1" },
      countrycodelist: [
        { label: "+91", value: "1" },
        { label: "+92", value: "2" },
      ],
      contactnumber: "8743877436",
      profileimage: [],
      resetpasswordmodalstatus: false,
    };
  },
  watch: {},
  validations() {
    return {
      firstname: {
        required: helpers.withMessage("Please enter first name", required),
      },
      lastname: {
        required: helpers.withMessage("Please enter last name", required),
      },
      email: {
        required: helpers.withMessage("Please enter email id", required),
      },
      contactnumber: {
        required: helpers.withMessage("Please enter contact number", required),
      },
    };
  },

  methods: {
    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        $event.preventDefault();
      }
    },
    previewFiles() {
      this.profileimage = this.$refs.userprofileFiles.profileimage;
    },
    profileeditbtn() {},
    profileupdatebtn() {
      this.v$.$validate(); // checks all inputs
      if (!this.v$.$error) {
        alert("successfully submitted.");
      } else {
        this.$error;
      }
    },
    resetpasswordModalOpen() {
      this.resetpasswordmodalstatus = true;
    },
    resetpasswordModalClose() {
      this.resetpasswordmodalstatus = false;
    },
  },
};
</script>
<style scoped>
.profile-reset-password-btn {
  letter-spacing: 0px;
  color: #3362db;
  font-size: 14px;
  font-family: "AcuminPro-Regular";
  padding: 0;
  text-decoration: none;
}
.profile-reset-password-btn:focus-visible,
.filter-apply-btn:focus-visible {
  outline: 0;
}
.profile-reset-password-btn:focus,
.filter-apply-btn:focus {
  box-shadow: none;
}
.custom-image-upload > input {
  display: none;
}
.custom-image-upload {
  position: absolute;
  right: 0;
  bottom: 0;
}
</style>